<template>
  <HeroVideo />
  <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
  <!-- <HelloWorld msg="Apogee Aerial" /> -->
</template>

<script>
// import video from './assets/timelapse.mp4'
import HeroVideo from './components/HeroVideo.vue'

export default {
  name: 'App',
  components: {
    HeroVideo
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
