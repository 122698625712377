<template>
  <video-background :src="require(`@/assets/video/apogee-hero.mp4`)" style="height: 100vh;" poster="./assets/logo.png">
    <h1 style="color: #2460A7FF;">Apogee Aerial</h1>
  </video-background>
</template>

<script>
export default {
  name: 'HeroVideo',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  color: #2460a7ff;
  /*
    color: #85B3D1FF;
    color: #B3C7D6FF;
    color: #D9B48FFF;
  */
}
</style>
