import { createApp } from 'vue'
import App from './App.vue'
import VideoBackground from 'vue-responsive-video-background-player'

// import '@aws-amplify/ui-vue'
// import Amplify from 'aws-amplify'
// import awsconfig from './aws-exports'

// Amplify.configure(awsconfig)

const Vue = createApp(App)
Vue.component('video-background', VideoBackground)
Vue.mount('#app')
